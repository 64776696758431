<template>
  <div class="dict">
    <div class="form-header-btn">
      <div class="header-title">字典管理</div>
      <div>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-input v-model="form.keyword" placeholder="标签名/描述" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">查询</el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="resetFun" class="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="type"
        label="类型"
        align="center"
        slot="type"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="标签名"
        align="center"
        slot="name"
      ></el-table-column>
      <el-table-column
        prop="value"
        label="数据值"
        align="center"
        slot="value"
      ></el-table-column>
      <el-table-column
        prop="description"
        label="描述"
        align="center"
        slot="description"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="创建人"
        width="150"
        align="center"
        slot="userName"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        slot="createTime"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" slot="handle" align="center" width="150">
        <template slot-scope="scope">
          <!-- v-if="isUpdate" -->
          <el-button type="text" size="small"  @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <!--  v-if="isDelete" -->
          <el-button type="text" size="small" @click="handleDel(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </l-table>
    <!-- 对话框 -->
    <el-dialog
      :title="title == '0' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="38%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="dialogForm"
        label-width="5.20833vw"
        :rules="rules"
        ref="dialogForm"
      >
        <el-form-item class="form-choose-item" prop="type" label="类型">
          <el-input
            class="form-input"
            v-model="dialogForm.type"
            clearable
            :maxlength="50"
            placeholder="请输入类型"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="name" label="标签名">
          <el-input
            class="form-input"
            v-model="dialogForm.name"
            clearable
            :maxlength="50"
            placeholder="请输入标签名"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="value" label="数据值">
          <el-input
            class="form-input"
            v-model="dialogForm.value"
            clearable
            :maxlength="50"
            placeholder="请输入数据值"
          />
        </el-form-item>

        <el-form-item class="form-choose-item" prop="description" label="描述">
          <el-input
            class="form-input"
            v-model="dialogForm.description"
            clearable
            :maxlength="50"
            placeholder="请输入描述"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="sort" label="排序">
          <el-input
            class="form-input"
            v-model="dialogForm.sort"
            clearable
            :maxlength="10"
            placeholder="请填写排序，只支持数字"
            input-number
          />
        </el-form-item>
        <el-form-item class="mt0">
          <el-button
            type="primary"
            v-if="title == '0'"
            @click="dialogAdd('dialogForm')"
            >新增</el-button
          >
          <el-button type="primary" v-else @click="handleEditBtn('dialogForm')"
            >修改</el-button
          >
          <el-button @click="handleClose" class="reset">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "类型",
          slotName: "type",
        },
        {
          label: "标签名",
          slotName: "name",
        },
        {
          label: "标识",
          slotName: "value",
        },

        {
          label: "描述",
          slotName: "description",
        },
        {
          label: "创建人",
          slotName: "userName",
        },
        {
          label: "创建时间",
          slotName: "createTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        keyword: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      dialogForm: {
        type: "",
        name: "",
        value: "",
        description: "",
        sort: "",
      },
      tableData: [],
      //   pagination: {
      //     //分页信息
      //     total: 30, // 总页数
      //     currentPage: 1, // 当前页数
      //     Size: 10, // 每页显示多少条
      //   },
      // 对话框表单校验
      rules: {
        name: [
          {
            required: true,
            message: "请输入标签名",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请输入类型",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "请输入数据值",
            trigger: "blur",
          },
          // { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        description: [
          {
            required: true,
            message: "请输入描述",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写排序，只支持数字",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "blur" },
        ],
      },
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
    // menulist一直在时这个方法不可缺少

    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊");
      this.permissionButtonList = data;
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      this.$api
        .fetchListDict(
          Object.assign({
            current: this.page.num,
            size: this.page.size,
            keyword: this.form.keyword,
          })
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      this.page.num = 1;
      this.init();
    },
    /** 表单-新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.title = "0";
      // this.dialogForm = {
      //   sort: "",
      // };
    },
    /** 对话框-新增 */
    dialogAdd(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.$api.addObjDict(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("添加成功");
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    /** 对话框-修改提交 */
    handleEditBtn(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.$api.putObjDict(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    /** 对话框-关闭事件 */
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.dialogForm = {
          type: "",
          name: "",
          value: "",
          description: "",
          sort: "",
        };
        this.$refs["dialogForm"].clearValidate();
      });
    },
    /** 行内-编辑 */
    handleEdit(row) {
      this.dialogForm = {
        value: row.value,
        name: row.name,
        type: row.type,
        description: row.description,
        sort: row.sort,
        id: row.id,
      };
      this.title = "1";
      this.dialogVisible = true;
    },
    /** 行内-删除 */
    handleDel(row) {
      // `确定要删除该条数据吗?`
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该条数据？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该条数据，删除后，将无法恢复。"
          ),
        ]),
        "操作确认",
        {
          type: "warning",
          iconClass: "el-icon-question",
          closeOnClickModal: false,
          customClass: 'addChild',
        }
      ).then(() => {
        this.$api.delDict(row).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
    /deep/.el-table th > .cell {
      padding-left: 14px;
      border-left: 2px solid #e4ebf5;
    }
    /deep/.el-table th:first-child > .cell {
      border-left: transparent;
    }
  }
}
</style>
<style>
.addChild .el-button{
  width: 96px;
  /* height: 38px; */
}
</style>
